<template>
  <div class="spinner-wrapper">
    <div class="spinner">
      <div v-for="index in bars" :style="styleObject(index)" :key="'bar' + index"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bars: 12
    }
  },
  methods: {
    styleObject(index) {
      const pos = (index - 1) * (360 / this.bars)
      const radius = 5
      const time = 1
      const delay = -1 + (time / this.bars) * (index - 1)

      return {
        animationDuration: `${time}s`,
        transform: `translate(-50%, -50%) rotate(${pos}deg) translateY(-${radius}px)`,
        animationDelay: `${delay}s`
      }
    }
  }
}
</script>

<style scoped>
.spinner-wrapper {
  position: relative;
  max-height: 100%;
  height: 100%;
  width: inherit;
}
div.spinner {
  margin: auto;
  height: 100%;
  width: 20px;
}

div.spinner div {
  position: absolute;
  width: 1px;
  height: 4px;
  background: var(--var-txt-color);
  opacity: 1;
  border-radius: 50px;
  box-shadow: 0 0 3px rgba(0,0,0,0.2);
  animation-name: fade;
  animation-direction: linear;
  animation-iteration-count: infinite;
  top: 50%;
  left: 50%;
}

@keyframes fade {
  from {opacity: 1;}
  to {opacity: 0.25;}
}
</style>