<template>
    <div class="field-set">
        <div class="input">
            <CurrencySelect :currencies="currencies" :selectedCurrency="activeCurrency" @currency="updateCurrency"/>
            <InputAmount :modelValue="props.amountObject?.value" @update:modelValue="updateAmount" :max="balance" :placeholder="'0.000000'" style="text-align: end; color: var(--var-txt-color);"/>
        </div>
        <div class="footer">
            <h6>Balance: {{ balance }} <span class="max-btn" v-show="balance > 0" @click="updateAmount(balance)">(Max)</span></h6>
            <h6>{{ balance > 0 && props.amountObject?.value > 0 ? `${calculateRelativeAmount(props.amountObject?.value)}%` : null }}</h6>
        </div>
        <div class="loader-overlay" v-if="loading"></div>
    </div>
</template>

<script setup>
import CurrencySelect from '@/components/CurrencySelect.vue'
import InputAmount from './InputAmount.vue'

import Big from 'big.js'
import { ref, computed, inject } from 'vue'
const store = inject('store')
// eslint-disable-next-line
const props = defineProps(['currencies', 'amountObject', 'loading'])
// eslint-disable-next-line
const emit = defineEmits(['update'])

const balance = computed(() => {
    if(!props.amountObject?.currency) return 0
    return store.getters.getBalance(props.amountObject?.currency, props.amountObject?.issuer)
})

const calculateRelativeAmount = (value) => {
    return Math.round(value / balance.value * 100)
}

const amount = ref(null)
const activeCurrency = computed(() => {
    if(!props.amountObject || !props.amountObject?.currency) return null
    else return  {
        issuer: props.amountObject?.issuer,
        currency: props.amountObject?.currency
    }
})

const updateAmount = (value) => {
    amount.value = value
    if(Number(props.amountObject?.value) !== Number(value) && !isNaN(value)) emit('update', amountObj.value)
}

const amountObj = computed(() => {
    const currency = activeCurrency.value
    if(!currency) return null

    return currency.code === store.state.ledger.native_currency ? Big(props.amountObject.value).times(1_000_000).toString() : {
        issuer: currency.issuer,
        currency: currency.currency,
        value: amount.value.toString()
    }
})

const updateCurrency = (value) => {
    const obj = {
        issuer: value.issuer_account,
        currency: value.code,
        value: null
    }
    emit('update', obj)
}
</script>

<style scoped>
.field-set {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: var(--grey3);
    border: 1px solid var(--grey2);
    border-radius: 10px;

    position: relative;
}
.field-set > .input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.field-set > .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.max-btn {
    color: var(--blue);
    cursor: pointer;
}
.loader-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
}
</style>
