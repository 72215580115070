import { ref } from 'vue'

const currencies = ref({})

const currencyFormat = (amount, currency) => {
    amount = Number(amount)
    if(amount === null || Number.isNaN(amount)) amount = 0 // Set amount to 0 when there is no trustline

    var integer = Math.trunc(amount)
    var decimal = amount % 1
    
    switch(currency) {
        case 'XRP':
        case 'XAH':
            return amount = Number(amount / 1_000_000).toFixed(6)
        case 'EUR':
        case 'USD':
            return amount = Number(amount).toFixed(4)
        default:
            decimal = decimal.toFixed(8)
            return (Number(integer) + Number(decimal)).toFixed(8)
    }
}

function isHex(string) {
    var re = /[0-9A-Fa-f]{6}/g
    const isValid = re.test(string) && !isNaN( parseInt(string,16) )
    re.lastIndex = 0
    return isValid
}

const hexToString = (hex) => {
    let temp = ''
    for (var n = 0; n < hex.length; n += 2) {
        temp += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
    }
    return temp.trim();
}

const parseCurrencyCode = (code) => {
    if(!isHex(code)) return code;
    var hex = code.toString();
    switch (hex.substring(0, 2)) {
        case '02': {
            const binary = Buffer.from(hex, 'hex');
            return binary.slice(8).toString('utf-8').trim();
        }
        case '03':
            return code
        default:
            return hexToString(code)
    }
}

const currencyCodeFormat = (code, maxLength = 4) => {
    if (typeof code !== 'string') return '---';

    if(Object.hasOwn(currencies.value, code) && typeof currencies.value[code] === 'string') {
        // ●
        if(code.slice(0, 2) === '03') return currencies.value[code]
        else return currencies.value[code].slice(0, maxLength)
    }
    try {
        if(code.toString().substring(0, 2) === '03') {
            const url = `https://xumm.app/api/v1/platform/amm-meta/${code}`;
            currencies.value[code] = '-LP-';
            fetch(url)
                .then(response => {
                    if (!response.ok) throw new Error('Network response was not ok');
                    return response.json();
                })
                .then(data => {
                    const name = `${isHex(data.pair[0].currency) ? hexToString(data.pair[0].currency) : data.pair[0].currency }-${isHex(data.pair[1].currency) ? hexToString(data.pair[1].currency) : data.pair[1].currency }`
                    currencies.value[code] = name;
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    throw error
                });
        } else {
            currencies.value[code] = parseCurrencyCode(code)
        }
    } catch (e) {
        currencies.value[code] = 'xxx'
    }
    return currencies.value[code]
};

const epochToDate = (epoch) => {
    let date = new Date('2000-01-01T00:00:00Z')
    date.setUTCSeconds(epoch)

    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    const hour = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()

    const makeDouble = (value) => {
        value = value.toString()
        if(value.length === 1) return '0' + value
        return value
    }
    return `${year}-${makeDouble(month)}-${makeDouble(day)} ${makeDouble(hour)}:${makeDouble(minutes)}:${makeDouble(seconds)}`
}

const thousandSeperators = (value) => {
    const str = value.toString().split('.', 2)
    const int = str[0] || '0'
    const frac = str[1] || ''

    return int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (str[1] ? '.' + frac : '')
}

const quantityFormat = (value, isNative, rate = 1) => {
    value = Number(value)
    if(isNaN(value)) return NaN

    if(isNative) value = value / 1_000_000

    if(value >= 1000) return thousandSeperators(Math.floor(value))
    else if(value > 1) return round(value, 2)
    else if(value > 0) {
        const magnitude = Math.floor(Math.log10(rate))
        return round(value, magnitude < 2 ? 2 : magnitude)
    } else return 0
}

const round = (value, decimals) => {
    value = Number(value)

    if(isNaN(value)) return NaN

    if(value < 1 && value > 0) {
        const log = Math.log10(value)
        const exponent = Math.ceil(log)

        return value.toFixed( Math.abs(exponent - decimals) )
    } else if (value >= 1) {
        const integerLength = (value.toFixed(0)).length
        return value.toPrecision(decimals + integerLength)
    } else if(value === 0) return '0.' + '0'.repeat(decimals)
    else NaN
}

const maxDecimals = (float) => {
    const value = Math.trunc(float)
    const length = value.toString().length
    if(length > 1) {
        return 2
    } else {
        if(value < 1) {
            return 4
        } else {
            return 3
        }
    }
}

const priceFormat = (value, decimals) => {
    const precision = isNaN(decimals) || decimals < 0 ? maxDecimals(value) : decimals
    const rounded = round(value, precision)
    return rounded
}

const prefixNumber = (value, digits) => {
    if(isNaN(Number(value)) || value < 0) return 'NaN'
    if(isNaN(digits)) digits = 4

    if(Number(value) < 1000) return quantityFormat(value)
    value = Math.trunc(value)
    
    const SIprefixList = ['', 'k', 'M', 'B', 'T', 'Q', 'Quint', 'H', 'S', 'O', 'N', 'D', 'U']
    const exp = Math.ceil(Math.log10(Number(value) + 1))
    const index = Math.ceil( (exp / 3) - 1 )
    let prefix = SIprefixList[index]

    if(!prefix) return value
    
    const pos = exp - (3 * index)
    if(pos === 3) return `${value.toString().slice(0,digits)}${prefix}`
    else {
        let string = value.toString()
        string = string.substring(0, pos) + '.' + string.substring(pos, digits) + prefix
        return string
    }
} 

export {
    currencyFormat,
    currencyCodeFormat,
    epochToDate,
    quantityFormat,
    priceFormat,
    prefixNumber
}