<template>
    <PopUp class="popup" :header="header" :show="show" :options="{ directMode: false, button: false }" @close="$emit('close')" v-if="shouldShowPopUp">
        <template v-slot:body>
            <slot></slot>
        </template>
    </PopUp>
    <BottomSheet :show="show" :options="{ directMode: false, button: false }" @close="$emit('close')" v-else style="background-color: var(--var-bg-color); color: var(--var-txt-color);">
        <template v-slot:sheet>
            <slot></slot>
        </template>
    </BottomSheet>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import PopUp from './PopUp.vue'
import BottomSheet from './BottomSheet.vue'

const shouldShowPopUp = ref(window.innerWidth > 600);

const updateWindowWidth = () => {
    shouldShowPopUp.value = window.innerWidth > 600;
};

onMounted(() => {
    window.addEventListener('resize', updateWindowWidth);
});

onUnmounted(() => {
    window.removeEventListener('resize', updateWindowWidth);
});

// eslint-disable-next-line
defineProps(['header', 'show'])
// eslint-disable-next-line
defineEmits(['close'])

</script>
