<template>
    <div class="swap-view">
        <nav>
            <a class="btn" style="margin-left: auto;" @click="modelActive = true">
                <QuestionMark class="invert-color"/>
            </a>
        </nav>
        <SwapCard />
    </div>
    <OverlayOption :show="modelActive" @close="modelActive = false">
        <h3 style="margin-top: 20px;">About</h3>
        <p style="text-align: justify; padding: 5px 15px;">The Swap xApp, uses Path Finding on the Ledger. The algorithm scans the Ledger to discover the optimal routes for asset exchanges. It evaluates multiple pathways and selects the one that offers the best rates and lowest fees.</p>
    </OverlayOption>
</template>

<script setup>
import QuestionMark from '@/assets/QuestionMark.vue'
import SwapCard from '@/components/SwapCard.vue'
import OverlayOption from '@/components/OverlayOption.vue'
import { ref } from 'vue'

const modelActive = ref(false)

</script>

<style>
nav {
    display: flex;
    flex-direction: row;
}
.swap-view {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 50px 15px;
    max-width: 350px;
    overflow: auto;
}
</style>
