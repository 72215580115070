<template>
    <header>
        <h4>{{ isSource ? 'Receive' : 'Send' }} Alternatives</h4>
        <h6 v-show="loading !== true">
            <TimeAgo :time="store.state.lastUpdatePathData"/>
        </h6>
        <Spinner v-show="loading === true"/>
    </header>
    <TransitionGroup name="list" tag="ul">
        <li v-for="(path, index) in alternatives" :key="`${path[!isSource ? 'source_amount' : 'destination_amount']?.currency || store.state.ledger.native_currency}_${path[!isSource ? 'source_amount' : 'destination_amount']?.issuer}`">
            <div class="swap-item">
                <div class="amount-container">
                    <img v-if="getMeta(path)?.img" class="currency-icon" :src="getMeta(path)?.img">
                    <div v-else class="currency-icon-alt">{{ currencyCodeFormat(path[!isSource ? 'source_amount' : 'destination_amount']?.currency) || store.state.ledger.native_currency }}</div>
                    <span>{{ typeof path[!isSource ? 'source_amount' : 'destination_amount'] === 'string' ? `${quantityFormat(path[!isSource ? 'source_amount' : 'destination_amount'], true, rates(path).in)}  ${store.state.ledger.native_currency}` : `${quantityFormat(path[!isSource ? 'source_amount' : 'destination_amount'].value, false, rates(path).in)} ${currencyCodeFormat(path[!isSource ? 'source_amount' : 'destination_amount'].currency)}` }}</span>
                </div>
                <a class="swap-btn" @click="sendPath(index)">
                    Swap
                    <ArrowDown style="transform: rotate(-90deg);"/>
                </a>
            </div>
            <div class="rates-container" @click="flipRate = !flipRate">
                <span>
                    {{ rateString(path) }}
                </span>
            </div>
        </li>
    </TransitionGroup>
    <div v-if="!alternatives || alternatives.length < 1">
        <h6 v-if="loading">Calculating...</h6>
        <h6 v-else-if="store.state.lastUpdatePathData">No swaps were found</h6>
        <h6 v-else>Enter an amount to start path finding</h6>
    </div>
</template>

<script setup>
import { quantityFormat, priceFormat, currencyCodeFormat } from '@/helpers/number-format.js'

import TimeAgo from './TimeAgo.vue'
import Spinner from '@/components/SpinnerItem.vue'
import ArrowDown from '@/assets/ArrowDown.vue'

import { ref, computed, inject } from 'vue'
const store = inject('store')
// eslint-disable-next-line
const props = defineProps(['isSource', 'currency', 'inputValue', 'loading'])
// eslint-disable-next-line
const emit = defineEmits(['execute'])

const paths = computed(() => {
    return store.getters.getOffers
})

const alternatives = computed(() => {
    return paths.value?.alternatives || []
})

const inputAmount = computed(() => {
    return props.inputValue
})

const getMeta = (path) => {
    const amount_type = !props.isSource ? 'source_amount' : 'destination_amount'
    const currency = path[amount_type]?.currency || store.state.ledger.native_currency
    const issuer = path[amount_type]?.issuer || null
    return store.getters.getCurrencyMeta(currency, issuer)
}

const flipRate = ref(true)
const rateString = (path) => {
    const flip = flipRate.value
    const receive = Object.hasOwn(paths.value, 'destination_amount') ? currencyCodeFormat(paths.value['destination_amount']?.currency || store.state.ledger.native_currency) : null

    const src = Object.hasOwn(path, 'source_amount') ? currencyCodeFormat(path['source_amount']?.currency || store.state.ledger.native_currency) : null
    const dest = Object.hasOwn(path, 'destination_amount') ? currencyCodeFormat(path['destination_amount']?.currency || store.state.ledger.native_currency) : receive || null
    try {
        const rate = priceFormat(rates(path)[flip ? props.isSource ? 'out' : 'in' : props.isSource ? 'in' : 'out'])
        return `1 ${flip ? src : dest} ≈ ${rate} ${flip? dest : src}`
    } catch(e) {
        console.error('Error calculating rate:', e)
        return null
    }
}

const rates = (path) => {
    const outputValue = path[!props.isSource ? 'source_amount' : 'destination_amount']?.value || path[!props.isSource ? 'source_amount' : 'destination_amount'] / 1_000_000
    const inputValue =  path[props.isSource ? 'source_amount' : 'destination_amount']?.value || (path[props.isSource ? 'source_amount' : 'destination_amount'] > 0 ? path[props.isSource ? 'source_amount' : 'destination_amount'] / 1_000_000 : null) || inputAmount?.value?.value || inputAmount.value / 1_000_000

    if(isNaN(outputValue) || isNaN(inputValue)) throw new Error('Value is NaN out: ' + JSON.stringify(outputValue) + ' In: ' + JSON.stringify(inputValue))
    return {
        out: outputValue / inputValue,
        in: inputValue / outputValue
    }
}

const sendPath = async (pathIndex) => {
    let pathFindCopy = JSON.parse(JSON.stringify(paths.value))
    pathFindCopy.alternatives = [pathFindCopy.alternatives[pathIndex]]
    emit('execute', pathFindCopy)
}
</script>

<style scoped>
ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
li {
    display: flex;
    flex-direction: column;
    background-color: var(--grey3);
    border: 1px solid var(--grey2);
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
}
.swap-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.amount-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-size: 1.2rem;
    font-weight: bold;
    font-family: din-2014, sans-serif;
}
.swap-btn {
    padding: 5px 15px;
    border-radius: 15px;
    color: white;
    background-color: var(--green);
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1rem;
    font-weight: bold;
    user-select: none;
    cursor: pointer;
}
.currency-icon,
.currency-icon-alt {
    width: 30px;
    height: 30px;
    border-radius: 5px;
}
.currency-icon-alt {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    font-weight: 600;
    font-family: din-2014, sans-serif;
    background-color: var(--grey2);
}
.rates-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: din-2014, sans-serif;
    color: var(--var-txt-color);
    font-size: 0.8rem;
    cursor: pointer;
    user-select: none;
}
@keyframes update {
    50% {
        background-color: rgba(255, 255, 255, 0.4);
    }
}
.list-enter-active {
    animation-name: update;
    animation-duration: 1s;
}

@keyframes flash {
    50% {
        transform: translate(10px, -5px);
        scale: 1.3;
        color: var(--green);
    }
}
.list-enter-active .amount-container > span {
    animation-name: flash;
    animation-duration: 1s;
}
</style>
