<template>
    <div v-if="loading === true" id="loading-view">
        <div style="height: 30px;">
            <Spinner />
        </div>
    </div>
    <div v-else-if="error !== false" id="error-view">
        <h3>Something went wrong</h3>
        {{ error.code || error }}
    </div>
    <SwapView v-else />
</template>

<script setup>
import SwapView from '@/views/SwapView.vue'
import Spinner from '@/components/SpinnerItem.vue'

import { ref, onMounted, inject } from 'vue';
import xapp from './plugins/xapp';

const store = inject('store')

const loading = ref(true)
const error = ref(false)

onMounted(async () => {
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get('xAppToken')

    const isTest = window.location.hostname === 'localhost'
    try {
        const data = isTest ? { account: process.env.VUE_APP_TEST_ACCOUNT, nodewss: process.env.VUE_APP_TEST_WS, nodetype: process.env.VUE_APP_NODE_TYPE } : await xapp.getTokenData(token)
        
        const nodeType = data.nodetype
        store.dispatch('setDefaultLedger', nodeType)

        const account = data.account
        store.dispatch('setAccount', account)

        const wss = data.nodewss
        store.dispatch('initWebSocket', wss)

        await Promise.all([
            store.dispatch('getServerInfo').catch(error => {
                throw { error: 'server', code: error }
            }),
            store.dispatch('getAccountInfo').catch(error => {
                throw { error: 'account', code: error }
            }),
            store.dispatch('getTrustLines').catch(error => {
                throw { error: 'lines', code: error }
            })
        ])
    } catch(e) {
        error.value = e
    }
    loading.value = false
})

</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    display: flex;
    justify-content: center;
    height: 100%;
}
#loading-view {
    display: flex;
    flex-direction: row;
    margin: auto;
}
h1,h2,h3,h4,h5,h6 {
    margin: 0;
    padding: 0;
}
.dark .invert-color {
    filter: invert(1)
}
.btn {
    min-width: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 600;
    user-select: none;
}
.btn-primary {
    background-color: var(--var-primary);
    color: white;
}
.btn-secondary {
    background-color: var(--var-secondary);
    color: grey;
}
.popup {
    height: 400px;
    background-color: var(--grey6);
    border: 1px solid var(--grey3);
}
</style>
