<template>
    <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg" class="invert-color">
    <g clip-path="url(#clip0_2_11864)">
    <path d="M9.26673 10.9863C9.39043 10.9863 9.50924 10.9619 9.62317 10.913C9.7371 10.8642 9.83638 10.7942 9.92102 10.7031L17.4796 2.95896C17.6554 2.78318 17.7433 2.57484 17.7433 2.33396C17.7433 2.16468 17.7042 2.01169 17.6261 1.87497C17.548 1.73825 17.4422 1.63083 17.3087 1.5527C17.1752 1.47458 17.0239 1.43552 16.8546 1.43552C16.6137 1.43552 16.4022 1.52015 16.2199 1.68943L8.73938 9.33591H9.78431L2.30384 1.68943C2.12806 1.52015 1.91647 1.43552 1.66907 1.43552C1.4998 1.43552 1.34843 1.47458 1.21497 1.5527C1.0815 1.63083 0.975708 1.73825 0.897584 1.87497C0.819458 2.01169 0.780396 2.16468 0.780396 2.33396C0.780396 2.45765 0.803182 2.57321 0.848755 2.68064C0.894328 2.78806 0.959432 2.88408 1.04407 2.96872L8.60267 10.7031C8.79797 10.8919 9.01933 10.9863 9.26673 10.9863Z" fill="black" fill-opacity="0.85"/>
    </g>
    <defs>
    <clipPath id="clip0_2_11864">
    <rect width="16.9629" height="10.4004" fill="white" transform="translate(0.780396 0.585907)"/>
    </clipPath>
    </defs>
    </svg>
</template>