<template>
    <a class="select-pill" :class="{ none: isNull }" @click="selectActive = true">
        <template v-if="!isNull">
            <img v-if="selected?.img" :src="selected.img">
            <span>{{ currencyCodeFormat(selected?.code) }}</span>
        </template>
        <span v-else>Select Currency</span>
        <ChevronDown />
    </a>
    <OverlayOption :show="selectActive" @close="selectActive = false" class="pair-select-popup" :header="'Select a TrustLine'">
        <div class="search-bar-container">
            <input type="search" placeholder="Search" v-model="search" />
        </div>
        <hr>
        <ul v-show="currencyList.length > 0">
            <li v-for="(currency, index) in currencyList" :key="currency" @click="setIndex(index)">
                <img v-if="currency.img" :src="currency.img">
                <div v-else class="currency-img-alt">{{ currencyCodeFormat(currency.code, 3) }}</div>
                <div class="currency-info">
                    <!-- Max length name 22 excluding... -->
                    <h4>{{ currency.name }}</h4>
                    <h6>{{ currency.issuer_name }}</h6>
                </div>
                <!-- {{ currency }} -->
            </li>
        </ul>
        <!-- If there is no search result -->
        <div v-if="search && !currencyList.length > 0">
            <span>No search result</span>
        </div>
    </OverlayOption>
</template>

<script setup>
import { currencyCodeFormat } from '@/helpers/number-format.js'

import ChevronDown from '@/assets/ChevronDown.vue'

import { ref, computed, defineProps, defineEmits } from 'vue'
import OverlayOption from '@/components/OverlayOption.vue'

const props = defineProps(['currencies', 'selectedCurrency'])
const emit = defineEmits(['currency'])

const selectedIndex = ref(0)
const isNull = computed(() => props.selectedCurrency === null)

const selected = computed(() => {
    return props.currencies.find(item => {
        return item.issuer_account === props.selectedCurrency.issuer && item.code === props.selectedCurrency.currency
    })
})

const selectActive = ref(false)
const search = ref(null)

const setIndex = (i) => {
    selectedIndex.value = i
    emit('currency', currencyList.value[selectedIndex.value])
    selectActive.value = false
}

const currencyList = computed(() => {
    const list = props.currencies
    if (search.value !== null && search.value.trim() !== '') {
        // Convert search term to lowercase for case-insensitive search
        const searchTerm = search.value.toLowerCase().trim();
        return list.filter(item => item.name.toLowerCase().includes(searchTerm));
    } else {
        return list;
    }
})

</script>

<style>
.select-pill {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    user-select: none;
    background-color: var(--grey6);
    padding: 5px 8px;
    border-radius: 15px;
    gap: 8px;
}
.select-pill.none {
    background-color: var(--var-primary);
}
.select-pill > img {
    width: 18px;
    height: 18px;
}
.select-pill > span {
    font-size: 0.9rem;
    font-weight: bold;
}
.select-pill > svg {
    width: 15px;
}
.pair-select-popup {
    display: flex;
    flex-direction: column;
}
.pair-select-popup input {
    background-color: transparent;
    border: 1px solid var(--grey3);
    outline: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 1.2rem;
    color: var(--var-txt-color);
}
.pair-select-popup ul {
    list-style: none;
    margin: 0;
    padding: 0 1.5rem;
    flex: 1;
    overflow: auto;
}
.pair-select-popup ul > li {
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;
    padding: 10px;
    border-radius: 2px;
    cursor: pointer;
}
.pair-select-popup ul > li:hover {
    background-color: rgb(255, 255, 255, 0.2);
}
.pair-select-popup ul > li > img,
.currency-img-alt {
    min-width: 30px;
    width: 30px;
    height: 30px;
    border-radius: 2px;
    margin-right: 10px;
}
.currency-info {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}
.currency-img-alt {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    font-weight: 600;
    font-family: din-2014, sans-serif;
    background-color: var(--grey2);
}
hr {
    width: 100%;
    border: none;
    border-top: 1px solid black;
}
</style>
