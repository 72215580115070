<template>
    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg" class="invert-color">
    <g clip-path="url(#clip0_2_12011)">
    <path d="M7.75961 0.449127C7.5057 0.449127 7.29737 0.52888 7.13461 0.688385C6.97185 0.847891 6.89047 1.0546 6.89047 1.3085V14.1698L6.98813 17.08L7.535 16.8846L4.03891 13.0468L1.81235 10.8593C1.73422 10.7812 1.64145 10.7226 1.53403 10.6835C1.4266 10.6445 1.3143 10.6249 1.19711 10.6249C0.956228 10.6249 0.756033 10.7063 0.596527 10.869C0.437022 11.0318 0.357269 11.2337 0.357269 11.4745C0.357269 11.7089 0.448415 11.9205 0.630707 12.1093L7.10532 18.6034C7.20297 18.7011 7.30714 18.7743 7.41782 18.8231C7.52849 18.872 7.64242 18.8964 7.75961 18.8964C7.88331 18.8964 8.0005 18.872 8.11118 18.8231C8.22185 18.7743 8.32276 18.7011 8.41391 18.6034L14.8983 12.1093C15.0741 11.9205 15.162 11.7089 15.162 11.4745C15.162 11.2337 15.0822 11.0318 14.9227 10.869C14.7632 10.7063 14.563 10.6249 14.3221 10.6249C14.2049 10.6249 14.0926 10.6445 13.9852 10.6835C13.8778 10.7226 13.785 10.7812 13.7069 10.8593L11.4803 13.0468L7.97446 16.8846L8.5311 17.08L8.62875 14.1698V1.3085C8.62875 1.0546 8.54737 0.847891 8.38461 0.688385C8.22185 0.52888 8.01352 0.449127 7.75961 0.449127Z" fill="black" fill-opacity="0.85"/>
    </g>
    <defs>
    <clipPath id="clip0_2_12011">
    <rect width="14.8047" height="18.4473" fill="white" transform="translate(0.357269 0.449127)"/>
    </clipPath>
    </defs>
    </svg>
</template>