import { createStore } from 'vuex'
import { XrplClient } from 'xrpl-client'
import xapp from '../plugins/xapp'
import Big from 'big.js'

import { currencyCodeFormat } from '../helpers/number-format.js'

export default createStore({
    state: {
        ledger: null,
        websocket: null,
        swapPath: null,
        paths: null,
        dataIndex: 0,
        account: null,
        destination: null,
        serverInfo: null,
        accountInfo: null,
        accountLines: null,
        destinationLines: null,
        metaData: [],
        lastUpdateDirectPathData: null,
        lastUpdatePathData: null
    },
    getters: {
        getOffers: state => {
            return state.paths
        },
        getSourceLines: state => {
            const lines = state.accountLines?.map(line => {
                const data = state.metaData.find(meta => meta.currency === line.currency && meta.issuer === line.account)
                return {
                    code: line.currency,
                    name: data?.meta?.token?.name || currencyCodeFormat(line.currency, 22),
                    issuer_account: line.account || data.issuer,
                    issuer_name: data?.meta?.issuer?.name,
                    img: data?.meta?.token?.icon ? new Image()
                        .src = data?.meta?.token?.icon : null,
                    meta: data,
                    object: line
                }
            }) || []
            // Todo make dynamic
            lines.unshift({
                code: state.ledger.native_currency,
                name: state.ledger.native_currency,
                issuer_name: null,
                img: new Image()
                    .src = `/images/${state.ledger.native_currency.toLowerCase()}.svg`
            })
            return lines
        },
        getCurrencyMeta: (state, getters) => (currency, issuer) => {
            // Todo make dynamic
            const lines = getters.getSourceLines
            if(currency === state.ledger.native_currency) {
                const meta = lines.find(item => item.code === currency)
                meta['object'] = state.accountInfo
                return meta
            } else return lines.find(item => item.code === currency && (issuer === state.account ? true : item.issuer_account === issuer))
        },
        getBalance: (state, getters) => (currency, issuer) => {
            if(currency === state.ledger.native_currency && !isNaN(state.accountInfo.Balance)) {
                const whole = Big(state.accountInfo.Balance).div(1_000_000)
                const reserves = Big(state.accountInfo.OwnerCount).times(state.serverInfo.validated_ledger.reserve_inc_xrp).plus(state.serverInfo.validated_ledger.reserve_base_xrp)
                return whole.minus(reserves).toString()
            } else return getters.getCurrencyMeta(currency, issuer).object.balance
        }
    },
    mutations: {
        setDefaultLedger: (state, payload) => {
            state.ledger = payload
        },
        setAccount: (state, account) => {
            state.account = account
            state.destination = account
        },
        setWebSocket: (state, socket) => {
            state.websocket = socket
        },
        setSwapPath: (state, data) => {
            state.swapPath = data
            state.lastUpdateDirectPathData = new Date()
        },
        setPathData: (state, data) => {
            state.paths = data
            state.lastUpdatePathData = new Date()
        },
        removePathData: (state) => {
            state.paths = null
            state.lastUpdatePathData = null
            state.swapPath = null
            state.lastUpdateDirectPathData = null
        },
        setServerInfo: (state, info) => {
            state.serverInfo = info
        },
        setAccountInfo: (state, info) => {
            state.accountInfo = info
        },
        setTrustLines: (state, lines) => {
            state.accountLines = lines
            state.destinationLines = lines
        },
        addMetaData: (state, data) => {
            state.metaData.push(data)
        },
        increaseIndex: (state) => {
            state.dataIndex++
        }
    },
    actions: {
        setDefaultLedger: (context, type) => {
            if(['XAHAU', 'XAHAUTESTNET'].includes(type)) {
                context.commit('setDefaultLedger', {
                    native_currency: 'XAH',
                    decimal_places: 6,
                    network_id: type === 'XAHAU' ? 21337 : 21338
                })
            } else {
                context.commit('setDefaultLedger', {
                    native_currency: 'XRP',
                    decimal_places: 6,
                    network_id: type === 'MAINNET' ? 0 : 1
                })
            }
        },
        setAccount: (context, account) => {
            if(!account || typeof account !== 'string') throw new Error('No valid account received')
            context.commit('setAccount', account)
        },
        initWebSocket: (context, wss) => {
            if(!wss || typeof wss !== 'string') throw new Error('No valid account wss endpoint received')

            const options = { NoUserAgent: true, MaxConnectTryCount: 5 }
            const socket =  new XrplClient(wss, options)

            socket.on('path', (data) => {
                const splitId = data?.id?.split('_')
                if(parseInt(splitId[0]) === context.state.dataIndex) {
                    switch(parseInt(splitId[1])) {
                        case 0:
                            context.commit('setSwapPath', data)
                            break
                        case 1:
                            context.commit('setPathData', data)
                            break
                    }
                }
                else console.log('no ID match:', data)
            })
            context.commit('setWebSocket', socket)
            return socket
        },
        getServerInfo: async (context) => {
            const server_info = await context.state.websocket.send({
                command: 'server_info'
            })
            context.commit('setServerInfo', server_info.info)
            return server_info
        },
        subscribeAccount: async (context) => {
            context.state.websocket.send({
                command: 'subscribe',
                accounts: [context.state.account]
            })
        },
        getAccountInfo: async (context) => {
            const account_info = await context.state.websocket.send({
                command: 'account_info',
                account: context.state.account
            })
            if(!Object.hasOwn(account_info, 'account_data') || account_info.status === 'error') throw new Error(account_info.error)
            context.commit('setAccountInfo', account_info.account_data)
            return account_info
        },
        getTrustLines: async (context) => {
            const account_lines = await context.state.websocket.send({
                command: 'account_lines',
                account: context.state.account
            })
            console.log(account_lines.lines)
            if(!Object.hasOwn(account_lines, 'lines')) throw new Error('actNoLines')
            if(account_lines.lines.length >= 88) throw new Error('actTooManyLines')
            context.commit('setTrustLines', account_lines.lines)

            account_lines.lines.forEach(line => {
                context.dispatch('getMetaData', `${line.currency}:${line.account}`)
            })
            return account_lines
        },
        getMetaData: async (context, identifier) => {
            const endpoint = 'https://s1.xrplmeta.org/token/'
            try {
                const response = await fetch(endpoint + identifier)
                if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`)
                const data = await response.json()
                context.commit('addMetaData', data)
            } catch (error) {
                console.error('Error fetching data: ', error)
            }
        },
        createPath: async (context, command) => {
            context.commit('increaseIndex')
            command = {
                id: `${context.state.dataIndex}_0`,
                command: 'path_find',
                subcommand: 'create',
                source_account: context.state.account,
                destination_account: context.state.destination,
                ...command
            }
            const resPath = await context.state.websocket.send(command)
            if(Object.hasOwn(resPath, 'error') || !Object.hasOwn(resPath, 'result')) throw resPath
            if(context.state.dataIndex) context.commit('setSwapPath', resPath.result)

            if(!Object.hasOwn(command, 'send_max') && !Object.hasOwn(command, 'source_currencies')) return
            if(Object.hasOwn(command, 'send_max')) {
                // delete command.send_max
                // todo make alternatives work for sending
                return context.commit('setPathData', resPath.result)
            } else {
                delete command.source_currencies
            }
            command.id = `${context.state.dataIndex}_1`
            const resAlts = await context.state.websocket.send(command)
            if(Object.hasOwn(resAlts, 'error') || !Object.hasOwn(resAlts, 'result')) throw resAlts
            if(context.state.dataIndex) context.commit('setPathData', resAlts.result)

        },
        closePath: (context) => {
            context.state.websocket.send({ command: 'path_find', subcommand: 'close' })
            context.commit('removePathData')
        },
        executePath: async (context, pathData) => {
            const path = pathData?.alternatives?.[0]
            const payment = {
                TransactionType: 'Payment',
                Account: context.state.account,
                Destination: context.state.destination,
                SendMax: path.source_amount,
                Amount: path.destination_amount || pathData.destination_amount,
                Paths: path.paths_computed,
                Flags: 131072
            }
            const res = await xapp.signPayload({
                txjson: payment
            })

            return res.response
        }
    },
    modules: {
    }
})
