<template>
    <div class="card">
        <header>
            <h4>Swap</h4>
            <SettingsMenu v-if="false">
                <a class="btn">
                    <CogSVG />
                </a>
            </SettingsMenu>
            <h6>
                <TimeAgo :time="store.state.lastUpdateDirectPathData"/>
            </h6>
        </header>
        <!-- source -->
        <FieldSet
            :currencies="accountLines"
            :amountObject="source"
            @update="(value) => updateAmount(value, true)"
            :loading="!pathIsSource && isLoading"
        />
        <a class="direction-btn" @click="swap()">
            <ArrowDown />
        </a>
        <!-- destination -->
        <FieldSet
            :currencies="accountLines"
            :amountObject="destination"
            @update="(value) => updateAmount(value, false)"
            :loading="pathIsSource && isLoading"
        />
        <a class="btn btn-primary" v-if="swapAmount > 0" @click="execute(swapPath)" style="background-color: var(--green);">
            Execute Swap
        </a>
        <a class="btn btn-secondary" v-else style="cursor: not-allowed;">
            Enter Amount
        </a>
    </div>

    <div class="card" style="margin-top: 30px;">
        <AlternativeList
            :is-source="pathIsSource"
            :currency="pathIsSource ? source : destination"
            :input-value="pathIsSource ? source : destination"
            :loading="isLoading"
            @execute="execute"
        />
    </div>
</template>

<script setup>
import TimeAgo from './TimeAgo.vue'
import SettingsMenu from '@/components/SettingsMenu.vue'
import CogSVG from '@/assets/CogSVG.vue'
import FieldSet from './FieldSet.vue'
import ArrowDown from '@/assets/ArrowDown.vue'
import AlternativeList from './AlternativeList.vue'

import Big from 'big.js'
import { ref, inject, computed, watch } from 'vue'
import xapp from '../plugins/xapp'

const store = inject('store')

const accountLines = computed(() => {
    return store.getters.getSourceLines
})

const source = ref({ currency: store.state.ledger.native_currency })
const destination = ref(null)

const swapPath = computed(() => {
    return store.state.swapPath
})

const execute = async (path) => {
    try {
        const res = await store.dispatch('executePath', path)
        resetValues()
        xapp.openTxViewer(res.txid, store.state.account)
    } catch(e) {
        if(e === null) return
        console.log('Error with executing order', e)
        alert('Something went wrong while executing the order')
    }
}

const pathIsSource = ref(true)
const isLoading = ref(false)
const destinationTimeout = ref(null)

const swapAmount = computed(() => {
    const swap = swapPath.value?.alternatives?.[0]?.[pathIsSource.value ? 'destination_amount' : 'source_amount']
    const swapValue = swap?.value || parseInt(swap) / 1_000_000 || null
    return swapValue
})

watch(() => swapAmount.value, (swapValue) => {
    if(swapValue) {
        if(pathIsSource.value && destination.value) destination.value.value = swapValue
        else if(!pathIsSource.value && source.value) source.value.value = swapValue
    }
})

const updateAmount = (amount_obj, isSource) => {
    if(!amount_obj) return
    isSource ? source.value = amount_obj : destination.value = amount_obj

    if(source.value?.currency === destination.value?.currency && source.value?.issuer === destination.value?.issuer) {
        isSource ? destination.value = null : source.value = null
    }

    if(isSource && destination.value?.value) destination.value.value = null
    else if(!isSource && source.value?.value) source.value.value = null

    if(!amount_obj.value > 0) closePath()

    if(isSource && source.value && source.value?.currency && source.value?.value > 0) {
        updatePath(amount_obj, isSource)
    } else if(!isSource && destination.value && destination.value?.currency && destination.value?.value > 0) {
        updatePath(amount_obj, isSource)
    }
}

const updatePath = (amount_obj, isSource) => {
    isLoading.value = true

    clearTimeout(destinationTimeout.value)

    if(source.value == null || destination.value == null) return isLoading.value = false

    destinationTimeout.value = setTimeout(async () => {
        try {
            pathIsSource.value = isSource
            await store.dispatch('closePath')
            if(!amount_obj) return
            if(amount_obj.currency === store.state.ledger.native_currency) amount_obj = Big(amount_obj.value).times(1_000_000).toString()

            let command
            if(isSource) {
                command = {
                    send_max: amount_obj,
                    destination_amount: destination.value.currency === store.state.ledger.native_currency ? -1 : {
                        issuer: destination.value.issuer,
                        currency: destination.value.currency,
                        value: -1
                    }
                }
            } else {
                command = {
                    destination_amount: amount_obj,
                    source_currencies: [{
                        currency: source.value.currency,
                        issuer: source.value.issuer
                    }]
                }
            }

            await store.dispatch('createPath', command)
            isLoading.value = false
        } catch(e) {
            console.error(e)
            isLoading.value = false
        }
        
    }, 250)
}

const resetValues = () => {
    if(destination.value?.value) destination.value.value = null
    if(source.value?.value) source.value.value = null
    closePath()
    Promise.all([
        store.dispatch('getServerInfo').catch(error => {
            throw { error: 'server', code: error }
        }),
        store.dispatch('getAccountInfo').catch(error => {
            throw { error: 'account', code: error }
        }),
        store.dispatch('getTrustLines').catch(error => {
            throw { error: 'lines', code: error }
        })
    ])
}

const closePath = () => {
    clearTimeout(destinationTimeout.value)
    store.dispatch('closePath')
    isLoading.value = false
}

const swap = () => {
    closePath()
    let temp = source.value
    source.value = destination.value
    destination.value = temp

    pathIsSource.value = !pathIsSource.value

    if(pathIsSource.value && destination.value?.value) destination.value.value = null
    else if(!pathIsSource.value && source.value?.value) source.value.value = null

    if(pathIsSource.value && source.value && source.value?.currency && source.value?.value > 0) {
        updatePath(source.value, pathIsSource.value)
    } else if(!pathIsSource.value && destination.value && destination.value?.currency && destination.value?.value > 0) {
        updatePath(destination.value, pathIsSource.value)
    }
}

</script>

<style>
.card {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: var(--grey5);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 10px;
}
.card > header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}
.direction-btn {
    cursor: pointer;
    padding: 5px;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    background-color: var(--grey6);
    margin: 5px auto;
}
.card > .btn {
    margin-top: 10px;
}
</style>
