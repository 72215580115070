<template>
    <span v-if="lastUpdate !== null">{{ lastUpdate > 0 ? `${lastUpdate}s ago` : 'now' }}</span>
</template>

<script setup>
import { ref, computed, onBeforeUnmount } from 'vue'
// eslint-disable-next-line
const props = defineProps(['time'])

const timeNow = ref(new Date())

const lastUpdate = computed(() => {
    const last = props.time
    if(!last) return null
    const elapsedTime = timeNow.value - last
    const seconds = Math.floor(elapsedTime / 1000)
    if(seconds < 0) return 0
    else return seconds
})

const updateTime = () => {
    timeNow.value = new Date()
}
const timeInterval = setInterval(updateTime, 1000)

onBeforeUnmount(() => {
    clearInterval(timeInterval)
});
</script>
