/* eslint-disable */
let OTT;
let tokenData;
let jwt;
let curatedAssets;

const apiEndPoint = 'https://xumm.app/api/v1/xapp-jwt';
const apiKey = process.env.VUE_APP_XAMAN_KEY;

const headers = () => {
    if (!jwt) throw new Error('No JWT present for header');
    return { headers: { Authorization: `Bearer ${jwt}` } };
};

const setOtt = (ott) => {
    if(!OTT && typeof ott === 'string') OTT = ott
    else throw new Error('Not a valid ott: ' + ott)
}

let tokenDataPromise

const resolveTokenPromise = async (promise) => {
    const res = await promise;
    if (!res.ok) throw new Error('Error getting Token Data');
    const data = await res.json();
    tokenData = data.ott;
    setJWT(data.jwt);
    return tokenData;
};

const getTokenData = async (ott = OTT) => {
    if(!ott) throw new Error('No OTT given');
    else if(!OTT) OTT = ott;

    if(tokenData) return tokenData;
    if(!tokenDataPromise) {
        tokenDataPromise = fetch(`${apiEndPoint}/authorize`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': apiKey,
                'x-api-ott': ott
            }
        });
    }
    return resolveTokenPromise(tokenDataPromise);
};

const setJWT = (token) => {
    jwt = token;
}

const sendCommandtoXumm = (command) => {
    if (typeof window.ReactNativeWebView === 'undefined') {
        throw new Error('This is not a react native webview');
    }
    window.ReactNativeWebView.postMessage(JSON.stringify(command));
};

const openSignRequest = (uuid) => {
    try {
        sendCommandtoXumm({
            command: 'openSignRequest',
            uuid: uuid
        });
    } catch (e) {
        throw e;
    }
};

const closeXapp = () => {
    try {
        sendCommandtoXumm({
            command: "close",
            refreshEvents: false
        });
    } catch (e) {
        throw e;
    }
};

const openExternalBrowser = (url) => {
    try {
        sendCommandtoXumm({
            command: 'openBrowser',
            url: url
        });
    } catch (e) {
        throw e;
    }
};

const openTxViewer = (tx, account) => {
    try {
        sendCommandtoXumm({
            command: 'txDetails',
            tx,
            account
        });
    } catch (e) {
        throw e;
    }
};

const openDestinationPicker = async () => {
    try {
        sendCommandtoXumm({
            command: 'selectDestination'
        })
        return await status('selectDestination')
    } catch(e) {
        if(e === null) return null
        else throw e
    }
}

let curatedAssetsPromise;

const getCuratedAssets = async () => {
    const resolve = async (promise) => {
        try {
            const res = await promise;
            if (!res.ok) {
                throw new Error('Error getting curated assets');
            }
            curatedAssets = await res.json();
            return curatedAssets;
        } catch (e) {
            throw new Error('Error getting curated assets');
        }
    };

    if (curatedAssetsPromise) {
        return resolve(curatedAssetsPromise);
    }
    if (curatedAssets && Object.keys(curatedAssets).length > 0 && curatedAssets.constructor === Object) {
        return curatedAssets;
    }
    curatedAssetsPromise = fetch(`${apiEndPoint}/curated-assets`, headers())
        .then(res => {
            if (!res.ok) {
                throw new Error('Error getting curated assets');
            }
            return res;
        })
        .then(res => res.json())
        .catch(() => {
            throw new Error('Error getting curated assets');
        });

    return resolve(curatedAssetsPromise);
};

const status = (method) => {
    return new Promise((resolve, reject) => {
        function message(event) {
            window.removeEventListener("message", message);
            document.removeEventListener("message", message);

            const data = JSON.parse(event.data);
            if(method === data.method || (method === 'openSignRequest' && data.method === 'payloadResolved') ) {
                switch(method) {
                    case 'openSignRequest':
                        if (data.reason === 'SIGNED') return resolve(data);
                        else return reject(null);
                    case 'selectDestination':
                        if (data.reason === 'SELECTED') return resolve(data);
                        else return reject(null)
                }
            } else return reject(null)
        }
        //iOS
        window.addEventListener('message', message);
        //Android
        document.addEventListener('message', message);
    });
};

const payload = async (payload) => {
    try {
        const res = await fetch(`${apiEndPoint}/payload`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...headers().headers
            },
            body: JSON.stringify(payload)
        });
        if (!res.ok) {
            throw new Error('Error creating payload');
        }
        const data = await res.json();
        openSignRequest(data.uuid);
        await status('openSignRequest');
        const result = await fetch(`${apiEndPoint}/payload/${data.uuid}`, headers());
        if (!result.ok) {
            throw new Error('Error retrieving payload');
        }
        return result.json();
    } catch (e) {
        if (e === null) throw null;
        throw e;
    }
};

const setUserData = async (key, payloadJSON) => {
    try {
        const res = await fetch(`${apiEndPoint}/userdata/${key}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...headers().headers
            },
            body: JSON.stringify(payloadJSON)
        });
        if (!res.ok) {
            throw new Error('Error posting userData to XUMM');
        }
    } catch (e) {
        console.error('Post userData to XUMM:', e);
        throw e;
    }
};

const getUserData = async (key) => {
    try {
        const res = await fetch(`${apiEndPoint}/userdata/${key}`, headers());
        if (!res.ok) {
            throw new Error('Error getting userData from XUMM');
        }
        const data = await res.json();
        return data.data;
    } catch (e) {
        console.error('Get userData from XUMM:', e);
        throw e;
    }
};

const versionCheck = (v1, v2) => {
    var v1parts = v1.split('.');
    var v2parts = v2.split('.');

    // First, validate both numbers are true version numbers
    function validateParts(parts) {
        for (var i = 0; i < parts.length; ++i) {
            if (!/^\d+$/.test(parts[i])) {
                return false;
            }
        }
        return true;
    }
    if (!validateParts(v1parts) || !validateParts(v2parts)) {
        return NaN;
    }

    for (var i = 0; i < v1parts.length; ++i) {
        if (v2parts.length === i) {
            return 1;
        }

        if (v1parts[i] === v2parts[i]) {
            continue;
        }
        if (v1parts[i] > v2parts[i]) {
            return 1;
        }
        return -1;
    }

    if (v1parts.length != v2parts.length) {
        return -1;
    }

    return 0;
};

export default {
    setOtt,
    getTokenData,
    setJWT,
    closeXapp,
    signPayload: payload,
    getCuratedAssets,
    openExternalBrowser,
    openTxViewer,
    openDestinationPicker,
    setUserData,
    getUserData,
    versionCheck
}
